// CreditsModal.tsx
import React from 'react';
import styled from 'styled-components';

const HyperLink = styled.a`
  color: #007bff; /* Change link color as needed */
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 35px;
  border-radius: 4px;
  gap: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-align: center;
`;

const CommandButton = styled.button`
  margin-right: 0em;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill= "black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Start Timer button */}
    {/* Example icon: */}
    <path d="M11.978,23.941A11.964,11.964,0,1,1,23.941,11.978,11.976,11.976,0,0,1,11.978,23.941Zm0-21.927a9.964,9.964,0,1,0,9.963,9.964A9.975,9.975,0,0,0,11.978,2.014Z"/><path d="M16,17a1,1,0,0,1-.707-.293l-8-8A1,1,0,1,1,8.707,7.293l8,8A1,1,0,0,1,16,17Z"/><path d="M8,17a1,1,0,0,1-.707-1.707l8-8a1,1,0,1,1,1.414,1.414l-8,8A1,1,0,0,1,8,17Z"/>
  </svg>
);

interface CreditsModalProps {
  onClose: () => void;
  children?: React.ReactNode; // Add this line
}

const CreditsModal: React.FC<CreditsModalProps> = ({ onClose, children}) => {
  return (
    <ModalOverlay>
      <ModalContent>
        {children}
        <p>This web app was created by <HyperLink href="https://github.com/H4mes">Hamish Dawe</HyperLink> for <HyperLink href="https://blog.nus.edu.sg/phcdgs/#.YgX-w5YRWUk">PharmaNUS</HyperLink>.</p>
        <p>The crossword was made using open source code, check it out <HyperLink href="https://github.com/JaredReisinger/react-crossword">here</HyperLink>. All icons are from <HyperLink href="https://thenounproject.com/browse/collection-icon/basic-icons-72587/?p=1">Basic Icons by DailyPM</HyperLink> and <HyperLink href="https://thenounproject.com/creator/raphaelbuquet/">Raphaël Buquet</HyperLink> from the Noun Project.</p>
        <p><div style={{ color: 'rgb(0, 61, 124)', fontWeight: 'bold' }}>
          Many thanks to these drug list and clue contributors:<br />
          </div>
          <div style={{ color: 'rgb(239, 124, 0)', fontWeight: 'bold' }}>
          Wong Hui Jie <br/>
          Tan Wei Zhao, Sidney <br/>
          Darrell Hartanto 
          </div>
        </p>
        <CommandButton onClick={onClose} title="Close">
            {<CloseIcon/>}
        </CommandButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default CreditsModal;
