import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import Crossword, {
  CrosswordImperative,
  CrosswordProps,
  CrosswordProviderImperative,
  CrosswordProviderProps,
  CrosswordGridProps,
  CrosswordContext
} from '@jaredreisinger/react-crossword';
import styled from 'styled-components';
import './App.css';
import PopupModal from './PopupModal';
import CreditsModal from './CreditsModal';
import PromptModal from './PromptModal';
import MoreModal from './MoreModal';
//import { generateNewData } from './Generator';
import { formatGrid, CrosswordData, ClueData } from './formatGrid';

const CrossStyle = styled.span`
  font-size: 50px;
  font-weight: 900;
  color: #EF7C00;
`
const BlueWord = styled.span`
  color: #003D7C;
`

const Footer = styled.footer`
  background-color: #f5f5f5;
  text-align: center;
  width: 100%
`;

const FooterText = styled.p`
  font-size: 10px;
`;

const FooterButton = styled.button<{ $primary?: boolean; }>`
  background: ${props => props.$primary ? "rgb(0,61,124)" : "rgb(239,124,0)"};
  color: ${props => props.$primary ? "rgb(239,124,0)" : "rgb(0,61,124)"};

  font-size: 12px;
  font-weight: bold;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid black;
  border-radius: 3px;
  cursor: pointer;
`;

const FooterLink = styled.a`
  color: #007bff; /* Change link color as needed */
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const HeaderLink = styled.a`
  color: #EF7C00; /* Change link color as needed */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Page = styled.div`
  max-width: 100w;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.h1`
  margin-bottom: 5px;
  text-align: center;

`;

const Toolbar = styled.div`
  margin: 0.15em 0;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  gap: 0.5em;
  max-height: 20em;
  max-width: 100%;
  align-items: stretch; /* Stretch items to fill the container width */
  justify-content: space-between;
  
  @media (min-width: 640px) {
    /* On screens wider than 768px, switch to row layout */
    flex-direction: row;
    align-items: center;
  }
  
  .topic, .timer, .commands {
    flex: 1; /* Expand to fill available space */
    display: flex;
    justify-content: center;
    white-space: nowrap;
    
    @media (min-width: 640px) {
      justify-content: flex-start; /* Align to the left */
    }
    
    &:not(:first-child) {
      margin-top: 0.5em; /* Add spacing between items */
      
      @media (min-width: 640px) {
        margin-top: 0; /* Reset margin for row layout */
        margin-left: 0.5em; /* Add spacing between items in row layout */
      }
    }
  }
`;


const CrosswordWrapper = styled.div`
  max-width: 100%;
  min-width: 280px;
  /* and some fun making use of the defined class names */
  .crossword.correct {
    rect {
      stroke: rgb(100, 200, 100) !important;
    }
    svg > rect {
      fill: rgb(100, 200, 100) !important;
    }
    text {
      fill: rgb(100, 200, 100) !important;
    }
  }

  .clue.correct {
    ::before {
      content: '\u2713'; /* a.k.a. checkmark: ✓ */
      display: inline-block;
      text-decoration: none;
      color: rgb(100, 200, 100);
    }

    text-decoration: line-through;
    color: rgb(130, 130, 130);
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TopicTitle = styled.span`
font-style: italic;
`;

const TimerDigits = styled.span`
`;

const CommandButton = styled.button`
  margin-right: 0em;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const StartTimerIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Start Timer button */}
    {/* Example icon: */}
    <path d="M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM12,2A10,10,0,1,0,22,12,10.011,10.011,0,0,0,12,2Z"/><path d="M9,17.619a1,1,0,0,1-1-1V7.381a1,1,0,0,1,1.5-.866l8,4.619a1,1,0,0,1,0,1.732l-8,4.619A1,1,0,0,1,9,17.619Zm1-8.506v5.774L15,12Z"/>
  </svg>
);

const StopTimerIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Stop Timer button */}
    {/* Example icon: */}
    <path d="M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM12,2A10,10,0,1,0,22,12,10.011,10.011,0,0,0,12,2Z"/><path d="M10,16.993a1,1,0,0,1-1-1V8.019a1,1,0,1,1,2,0v7.974A1,1,0,0,1,10,16.993Z"/><path d="M14,16.993a1,1,0,0,1-1-1V8.019a1,1,0,1,1,2,0v7.974A1,1,0,0,1,14,16.993Z"/>
  </svg>
);

const HintIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Hint button */}
    {/* Example icon: */}
    <path d="M12,19C5.526,19,.44,12.9.227,12.634a1,1,0,0,1,0-1.268C.44,11.105,5.526,5,12,5s11.56,6.105,11.773,6.366a1,1,0,0,1,0,1.268C23.56,12.9,18.474,19,12,19ZM2.349,12C3.764,13.472,7.611,17,12,17s8.239-3.525,9.651-5C20.236,10.528,16.389,7,12,7S3.761,10.525,2.349,12Z"/><path d="M12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,12,10Z"/>
  </svg>
);

const ResetIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Reset button */}
    {/* Example icon: */}
    <path d="M13.469,22.531A10.525,10.525,0,0,1,8.68,21.38,1,1,0,0,1,9.594,19.6a8.4,8.4,0,0,0,3.875.93A8.531,8.531,0,1,0,4.938,12a1,1,0,0,1-2,0A10.531,10.531,0,1,1,13.469,22.531Z"/><path d="M3.994,14.458a1,1,0,0,1-.707-.292l-3-3A1,1,0,0,1,1.7,9.751l2.293,2.293L6.287,9.751A1,1,0,0,1,7.7,11.166l-3,3A1,1,0,0,1,3.994,14.458Z"/>
  </svg>
);

const DropdownIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Reset button */}
    {/* Example icon: */}
    <path d="M21,24H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H21a3,3,0,0,1,3,3V21A3,3,0,0,1,21,24ZM3,2A1,1,0,0,0,2,3V21a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1Z"/><path d="M12,15a1,1,0,0,1-.707-.293l-4-4A1,1,0,1,1,8.707,9.293l4,4A1,1,0,0,1,12,15Z"/><path d="M12,15a1,1,0,0,1-.707-1.707l4-4a1,1,0,0,1,1.414,1.414l-4,4A1,1,0,0,1,12,15Z"/>
  </svg>
);

const MoreIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Reset button */}
    {/* Example icon: */}
<path class="st0" d="M12.9,18.2c0,1.1-1.7,1.1-1.7,0C11.1,17.1,12.9,17.1,12.9,18.2"/>
<path class="st1" d="M12,5.4c-2.4,0-4.3,1.9-4.3,4.3c0,0.2,0.1,0.5,0.2,0.6c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.6-0.3
		c0.2-0.2,0.2-0.4,0.2-0.6c0-1.4,1.1-2.6,2.6-2.6s2.6,1.1,2.6,2.6s-1.1,2.6-2.6,2.6c-0.5,0-0.9,0.4-0.9,0.9v1.7
		c0,0.2,0.1,0.5,0.2,0.6c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.6-0.3c0.2-0.2,0.2-0.4,0.2-0.6v-1c1.9-0.4,3.4-2.1,3.4-4.1
		C16.3,7.3,14.4,5.4,12,5.4L12,5.4z"/>
<path d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10
		S17.5,2,12,2z"/>
</svg>
);

interface UniqueEntry {
  label: string;
  value: string;
}

function formatTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s`;
}

const data = {
  across: {
    2: {
      clue: '',
      answer: 'aa',
      row: 1,
      col: 0,
    },
    4: {
      clue: '',
      answer: 'aa',
      row: 3,
      col: 3,
    },
    7: {
      clue: '',
      answer: 'aaa',
      row: 4,
      col: 7,
    },
    9: {
      clue: '',
      answer: 'aaaa',
      row: 0,
      col: 13,
    },
    11: {
      clue: '',
      answer: 'aaa',
      row: 2,
      col: 13,
    },
    13: {
      clue: '',
      answer: 'aaaa',
      row: 4,
      col: 12,
    },
  },
  down: {
    1: {
      clue: '',
      answer: 'aaaaa',
      row: 0,
      col: 0,
    },
    3: {
      clue: '',
      answer: 'a',
      row: 2,
      col: 2,
    },
    5: {
      clue: '',
      answer: 'aaaaa',
      row: 0,
      col: 4,
    },
    6: {
      clue: '',
      answer: 'aaaa',
      row: 0,
      col: 6,
    },
    8: {
      clue: '',
      answer: 'aaaa',
      row: 0,
      col: 10,
    },
    10: {
      clue: '',
      answer: 'a',
      row: 1,
      col: 12,
    },
    12: {
      clue: '',
      answer: 'a',
      row: 3,
      col: 16,
    },
  },
};

let PopupHeader: string = 'Select a Topic';

// Function to parse CSV and get unique entries from the first column
async function parseCSVAndGetUniqueFirstColumnEntries(filePath: string): Promise<UniqueEntry[]> {
  try {
    // Fetch the CSV file
    const response = await fetch(filePath);
    const csvData = await response.text();

    // Split CSV data into rows and clean up the first column entries
    const rows: string[] = csvData.split('\n').map((row) => row.trim());
    const uniqueEntries: Set<string> = new Set();

    for (const row of rows) {
      const columns: string[] = row.split(',');
      const firstColumnEntry: string = columns[0].trim();

      if (firstColumnEntry) {
        // Remove double quotes surrounding the first column entry
        const cleanedEntry: string = firstColumnEntry.replace(/^"(.*)"$/, '$1');
        uniqueEntries.add(cleanedEntry);
      }
    }

    // Return an array of objects with label and value properties
    const result: UniqueEntry[] = Array.from(uniqueEntries).map((entry) => ({
      label: entry,
      value: entry,
    }));

    return result;
  } catch (error) {
    console.error('Error fetching or parsing the CSV file:', error);
    return [];
  }
}

function App() {
  document.title = "+Word";
  const [isCrosswordCorrect, setIsCrosswordCorrect] = useState<boolean>(false);
  
/*
  // Access the CrosswordContext to get selectedPosition and gridData
  const { selectedPosition, gridData } = useContext<CrosswordContextType>(CrosswordContext);

  const fillSelectedCellWithCorrectAnswer = useCallback(() => {
    // Get the current selected position's row and col
    const { row, col } = selectedPosition;
    console.log("current position ", row, col);
    // Check if the cell is used (i.e., a cell with a clue)
    if (gridData[row] && gridData[row][col] && gridData[row][col].used) {
      // Get the correct letter from the gridData
      //const correctLetter = gridData[row][col].answer;

      // Fill the current cell with the correct letter
      //crossword.current?.setGuess(row, col, correctLetter);
    }
  }, [selectedPosition, gridData]);

*/

  const filePath = '/data/drugs.csv'
  const [dropdownItems, setDropdownItems] = useState<UniqueEntry[]>([]);
  const [modifiedData, setData] = useState<CrosswordData>(data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch unique entries from the CSV file
        const entries = await parseCSVAndGetUniqueFirstColumnEntries(filePath);
        setDropdownItems([{ label: 'All Topics', value: '' }, ...entries]); // Update the state with unique entries
      } catch (error) {
        console.error('Error fetching or parsing the CSV file:', error);
      }
    };

    fetchData();
  }, []); // Run the effect only once on component mount

  const [timer, setTimer] = useState<number>(0);
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isTimerRunning]);

  const handleStartTimer = () => {
    setIsTimerRunning(true);
  };

  const handleStopTimer = () => {
    setIsTimerRunning(false);
  };

  const handleResetTimer = () => {
    setTimer(0);
    setIsTimerRunning(false);
  };

  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [isCreditsOpen, setIsCreditsOpen] = useState(false);
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  
  const handleGeneratePuzzle = async () => {
    //crossword.current?.reset();
    setIsCrosswordCorrect(false);
    console.log("Generating Puzzle...");
    const newData = await formatGrid(selectedOption);
    if (newData) {
      setData(newData);
      const processed = selectedOption === "" ? "All Topics" : selectedOption;
      setTitleSelected(processed);
    }
    setTimeout(() => {
      crossword.current?.reset();
      PopupHeader = "Select a Topic"
    }, 0);

    console.log("newData: ", newData);
    setIsPopupOpen(false);
    setTimer(0);
    setIsTimerRunning(true);
  }

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleOpenCredits = () => {
    setIsCreditsOpen(true);
  };

  const handleCloseCredits = () => {
    setIsCreditsOpen(false);
  };

  const handleOpenPrompt = () => {
    setIsPromptOpen(true);
  };

  const handleClosePrompt = () => {
    setIsPromptOpen(false);
  };

  const handleOpenMore = () => {
    setIsMoreOpen(true);
  };

  const handleCloseMore = () => {
    setIsMoreOpen(false);
  };

  const [selectedOption, setSelectedOption] = useState<string>(dropdownItems[0]?.value || '');
  const [titleSelected, setTitleSelected] = useState<string>("All Topics");

  const crossword = useRef<CrosswordImperative>(null);
  const crosswordGridRef = useRef<CrosswordGridProps>(null);

  const focus = useCallback<React.MouseEventHandler>((event) => {
    crossword.current?.focus();
  }, []);

  const fillAllAnswers = useCallback<React.MouseEventHandler>((event) => {
    crossword.current?.fillAllAnswers();
    setIsTimerRunning(false);
    setIsPromptOpen(false);
  }, []);

  const reset = useCallback<React.MouseEventHandler>((event) => {
    crossword.current?.reset();
  }, []);

  // We don't really *do* anything with callbacks from the Crossword component,
  // but we can at least show that they are happening.  You would want to do
  // something more interesting than simply collecting them as messages.
  const messagesRef = useRef<HTMLPreElement>(null);
  const [messages, setMessages] = useState<string[]>([]);

  const clearMessages = useCallback<React.MouseEventHandler>((event) => {
    setMessages([]);
  }, []);

  const addMessage = useCallback((message: string) => {
    setMessages((m) => m.concat(`${message}\n`));
  }, []);

  useEffect(() => {
    if (!messagesRef.current) {
      return;
    }
    const { scrollHeight } = messagesRef.current;
    messagesRef.current.scrollTo(0, scrollHeight);
  }, [messages]);

  // onCorrect is called with the direction, number, and the correct answer.
  const onCorrect = useCallback<Required<CrosswordProps>['onCorrect']>(
    (direction, number, answer) => {
      addMessage(`onCorrect: "${direction}", "${number}", "${answer}"`);
    },
    [addMessage]
  );

  // onLoadedCorrect is called with an array of the already-correct answers,
  // each element itself is an array with the same values as in onCorrect: the
  // direction, number, and the correct answer.
  const onLoadedCorrect = useCallback<
    Required<CrosswordProps>['onLoadedCorrect']
  >(
    (answers) => {
      addMessage(
        `onLoadedCorrect:\n${answers
          .map(
            ([direction, number, answer]) =>
              `    - "${direction}", "${number}", "${answer}"`
          )
          .join('\n')}`
      );
    },
    [addMessage]
  );

  // onCrosswordCorrect is called with a truthy/falsy value.
  const onCrosswordCorrect = useCallback<
    Required<CrosswordProps>['onCrosswordCorrect']
  >(
    (isCorrect) => {
      addMessage(`onCrosswordCorrect: ${JSON.stringify(isCorrect)}`);
      setIsCrosswordCorrect(isCorrect);
    },
    [addMessage]
  );

  useEffect(() => {
    if (isCrosswordCorrect) {
      setIsTimerRunning(false); // Stop the timer
      PopupHeader = 'Congratulations! Your time is ' + formatTime(timer);
      setIsPopupOpen(true);
    }
  }, [isCrosswordCorrect, timer]);

  // onCellChange is called with the row, column, and character.
  const onCellChange = useCallback<Required<CrosswordProps>['onCellChange']>(
    (row, col, char) => {
      addMessage(`onCellChange: "${row}", "${col}", "${char}"`);
    },
    [addMessage]
  );

  // all the same functionality, but for the decomposed CrosswordProvider
  const crosswordProvider = useRef<CrosswordProviderImperative>(null);

  const focusProvider = useCallback<React.MouseEventHandler>((event) => {
    crosswordProvider.current?.focus();
  }, []);

  const fillAllAnswersProvider = useCallback<React.MouseEventHandler>(
    (event) => {
      crosswordProvider.current?.fillAllAnswers();
    },
    []
  );

  const resetProvider = useCallback<React.MouseEventHandler>((event) => {
    crosswordProvider.current?.reset();
  }, []);

  // We don't really *do* anything with callbacks from the Crossword component,
  // but we can at least show that they are happening.  You would want to do
  // something more interesting than simply collecting them as messages.
  const messagesProviderRef = useRef<HTMLPreElement>(null);
  const [messagesProvider, setMessagesProvider] = useState<string[]>([]);

  const clearMessagesProvider = useCallback<React.MouseEventHandler>(
    (event) => {
      setMessagesProvider([]);
    },
    []
  );

  const addMessageProvider = useCallback((message: string) => {
    setMessagesProvider((m) => m.concat(`${message}\n`));
  }, []);

  useEffect(() => {
    if (!messagesProviderRef.current) {
      return;
    }
    const { scrollHeight } = messagesProviderRef.current;
    messagesProviderRef.current.scrollTo(0, scrollHeight);
  }, [messagesProvider]);

  // onCorrect is called with the direction, number, and the correct answer.
  const onCorrectProvider = useCallback<
    Required<CrosswordProviderProps>['onCorrect']
  >(
    (direction, number, answer) => {
      addMessageProvider(`onCorrect: "${direction}", "${number}", "${answer}"`);
    },
    [addMessageProvider]
  );

  // onLoadedCorrect is called with an array of the already-correct answers,
  // each element itself is an array with the same values as in onCorrect: the
  // direction, number, and the correct answer.
  const onLoadedCorrectProvider = useCallback<
    Required<CrosswordProviderProps>['onLoadedCorrect']
  >(
    (answers) => {
      addMessageProvider(
        `onLoadedCorrect:\n${answers
          .map(
            ([direction, number, answer]) =>
              `    - "${direction}", "${number}", "${answer}"`
          )
          .join('\n')}`
      );
    },
    [addMessageProvider]
  );

  // onCrosswordCorrect is called with a truthy/falsy value.
  const onCrosswordCorrectProvider = useCallback<
    Required<CrosswordProviderProps>['onCrosswordCorrect']
  >(
    (isCorrect) => {
      addMessageProvider(`onCrosswordCorrect: ${JSON.stringify(isCorrect)}`);
    },
    [addMessageProvider]
  );

  // onCellChange is called with the row, column, and character.
  const onCellChangeProvider = useCallback<
    Required<CrosswordProviderProps>['onCellChange']
  >(
    (row, col, char) => {
      addMessageProvider(`onCellChange: "${row}", "${col}", "${char}"`);
    },
    [addMessageProvider]
  );

  return (
    <Page>
      <Header><CrossStyle>+</CrossStyle><BlueWord>Word</BlueWord> by <HeaderLink href="https://blog.nus.edu.sg/phcdgs/#.YgX-w5YRWUk">PharmaNUS</HeaderLink></Header>
      <Toolbar>
        <div className="topic">
          <TopicTitle>
            {titleSelected}
          </TopicTitle>
        </div>
        <div className="timer">
          <TimerDigits>
            {formatTime(timer)}
          </TimerDigits>
        </div>
        <div className="commands">
          <CommandButton onClick={isTimerRunning ? handleStopTimer : handleStartTimer} title={isTimerRunning ? 'Stop Timer' : 'Start Timer'}>
            {isTimerRunning ? <StopTimerIcon /> : <StartTimerIcon />}
          </CommandButton>
          <CommandButton onClick={handleOpenPrompt} title="Fill all answers">
            {<HintIcon/>}
          </CommandButton>
          <CommandButton onClick={reset} title="Reset">
            {<ResetIcon/>}
          </CommandButton>
          <CommandButton onClick={handleOpenPopup} title="Select Topic">
            {<DropdownIcon/>}
          </CommandButton>
          <CommandButton onClick={handleOpenMore} title="Rules/Help">
            {<MoreIcon/>}
          </CommandButton>
        </div>
      </Toolbar>


      <CrosswordWrapper>
        <Crossword
          ref={crossword}
          data={modifiedData}
          storageKey="first-example"
          onCorrect={onCorrect}
          onLoadedCorrect={onLoadedCorrect}
          onCrosswordCorrect={onCrosswordCorrect}
          onCellChange={onCellChange}
          theme={{ allowNonSquare: true , numberColor: 'rgba(0,0,0, 0.7)'}}
        />
      </CrosswordWrapper>
      {isPopupOpen && (
        <PopupModal onClose={handleClosePopup} onGenerate={handleGeneratePuzzle}>
          {/* Add the dropdown menu inside the popup */}
          <p>{PopupHeader}</p>
          <select
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            style={{ fontSize: '18px' }}
          >
            {dropdownItems.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </PopupModal>
      )}
      {isCreditsOpen && (
        <CreditsModal onClose={handleCloseCredits}></CreditsModal>
      )}
      {isPromptOpen && (
        <PromptModal onClose={handleClosePrompt} onSolve={fillAllAnswers}>
          {/* Add the dropdown menu inside the popup */}
        </PromptModal>
      )}
      {isMoreOpen && (
        <MoreModal onClose={handleCloseMore}></MoreModal>
      )}
    <Footer>
        <FooterButton $primary onClick={handleOpenCredits}>About this Web App</FooterButton>
    </Footer>
    </Page>
  );
}

export default App;