import { generateNewData } from "./Generator";

export function formatGrid(selectedTopic) {
  function insertSortedByNumbers(targetArray, newArray) {
    let insertionIndex = targetArray.length;
  
    while (
      insertionIndex > 0 &&
      (newArray[1] < targetArray[insertionIndex - 1][1] ||
        (newArray[1] === targetArray[insertionIndex - 1][1] && newArray[2] < targetArray[insertionIndex - 1][2]))
    ) {
      insertionIndex--;
    }
  
    targetArray.splice(insertionIndex, 0, newArray);
  }

  async function formatItemstoCrossword(itemsPromise) {
    try {
      var minCol = 0;
      var minRow = 0;
      const items = await itemsPromise;

      for (let index = 0; index < items.length; index++) {
        if (items[index][1] < minRow) {
          minRow = items[index][1];
        }
        if (items[index][2] < minCol) {
          minCol = items[index][2];
        }
      }
      //console.log("Items from Generator: ");
      //console.log(items);
      //console.log("minrow: ", minRow, "mincol: ", minCol)

      const crosswordData = {
        across: {},
        down: {},
      };
      const reorderedArray = [];
      //console.log("Reordered array: ");
      //console.log(reorderedArray);
      for (let index = 0; index < items.length; index++) {
        let repackageItem = [items[index][0], items[index][1] - minRow, items[index][2] - minCol, items[index][3]];
        insertSortedByNumbers(reorderedArray, repackageItem);
      }
      let skippedIndex = 0;
      for (let index = 0; index < reorderedArray.length; index++) {
        const [[word, ...clues], row, col, direction] = reorderedArray[index];

        // Randomly select a clue from the available list of clues
        //console.log("Clues: ");
        //console.log(clues);
        const availableClues = clues.length;
        const randomIndex = Math.floor(Math.random() * availableClues);
        const selectedClue = clues[randomIndex];
        //console.log("Current item:", word, row, col, direction, "Clue Number: ", randomIndex);

        if (!selectedClue) {
          console.warn("No clue available for item at index ", index);
          continue; // Skip this item and move to the next one
        }

        const entry = {
          clue: selectedClue,
          answer: word,
          row: row,
          col: col,
        };

        if (direction === 0) {
          let tempIndex = index + 1 - skippedIndex;
          for (var dkey in crosswordData.down) {
            if (entry.row === crosswordData.down[dkey].row && entry.col === crosswordData.down[dkey].col) {
              tempIndex = dkey;
              skippedIndex += 1;
            }
          }
          crosswordData.across[tempIndex] = entry;
        } else if (direction === 1) {
          let tempIndex = index + 1 - skippedIndex;
          for (var akey in crosswordData.across) {
            if (entry.row === crosswordData.across[akey].row && entry.col === crosswordData.across[akey].col) {
              tempIndex = akey;
              skippedIndex += 1;
            }
          }
          crosswordData.down[tempIndex] = entry;
        } else {
          console.warn("Invalid direction value for item at index ", index);
          continue; // Skip this item and move to the next one
        }
      }

      console.log("Final crosswordData: ", crosswordData);
      return crosswordData;
    } catch (error) {
      console.error("Error occurred while processing items:", error);
      return null;
    }
  }
  const result = formatItemstoCrossword(generateNewData(selectedTopic));
  return result;
}