// PopupModal.tsx
import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 35px;
  border-radius: 4px;
  gap: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
`;


const GenerateButton = styled.button<{ $primary?: boolean; }>`
  background: ${props => props.$primary ? "black" : "white"};
  color: ${props => props.$primary ? "white" : "black"};

  font-size: 18px;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid black;
  border-radius: 3px;
  cursor: pointer;
`;

const CommandButton = styled.button`
  margin-right: 0em;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill= "black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Start Timer button */}
    {/* Example icon: */}
    <path d="M11.978,23.941A11.964,11.964,0,1,1,23.941,11.978,11.976,11.976,0,0,1,11.978,23.941Zm0-21.927a9.964,9.964,0,1,0,9.963,9.964A9.975,9.975,0,0,0,11.978,2.014Z"/><path d="M16,17a1,1,0,0,1-.707-.293l-8-8A1,1,0,1,1,8.707,7.293l8,8A1,1,0,0,1,16,17Z"/><path d="M8,17a1,1,0,0,1-.707-1.707l8-8a1,1,0,1,1,1.414,1.414l-8,8A1,1,0,0,1,8,17Z"/>
  </svg>
);

interface PopupModalProps {
  onClose: () => void;
  onGenerate: () => void;
  children: React.ReactNode; // Add this line to define the children prop
}

const PopupModal: React.FC<PopupModalProps> = ({ children, onClose, onGenerate}) => {
  return (
    <ModalOverlay>
      <ModalContent>
        {children}
        <GenerateButton $primary onClick={onGenerate}>Generate Puzzle</GenerateButton>
        <CommandButton onClick={onClose} title="Close">
            {<CloseIcon/>}
        </CommandButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default PopupModal;