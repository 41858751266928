export function generateNewData(selectedKeyword) {
  //var branches = 0;
  /*
  function parseCSVData(data, searchWord) {
    const lines = data.trim().split('\n');
    const result = [];
  
    for (const line of lines) {
      const columns = [];
      let currentColumn = '';
      let isWithinQuotes = false;
      let isEscapeNextChar = false;
  
      for (let i = 0; i < line.length; i++) {
        const char = line[i];
  
        if (isEscapeNextChar) {
          currentColumn += char;
          isEscapeNextChar = false;
        } else if (char === '\\' && isWithinQuotes) {
          isEscapeNextChar = true;
        } else if (char === ',' && !isWithinQuotes) {
          if(searchWord === "" || searchWord === currentColumn){
            columns.push(currentColumn);
          }
          currentColumn = '';
        } else if (char === '"') {
          isWithinQuotes = !isWithinQuotes;
        } else {
          currentColumn += char;
        }
      }
  
      // Add the last column (or the only column if there's just one) after the loop ends
      columns.push(currentColumn);
  
      // Ignore the first entry (index 0) and save the rest as items in a single array
      const items = columns.slice(1);
      result.push(items);
  
      // Reset the isWithinQuotes flag for the next line
      isWithinQuotes = false;
    }
  
    return result;
  }
  */
  function parseCSVData(data, searchWord) {
    const lines = data.trim().split('\n');
    const result = [];
    let isWithinQuotes = false;
  
    for (const line of lines) {
      const columns = [];
      let currentColumn = '';
      let isFirstColumn = true;
  
      for (let i = 0; i < line.length; i++) {
        const char = line[i];
  
        if (char === '"') {
          isWithinQuotes = !isWithinQuotes;
        } else if (char === ',' && !isWithinQuotes) {
          if (isFirstColumn) {
            isFirstColumn = false;
            if (searchWord === '' || currentColumn === searchWord) {
              columns.push(currentColumn);
            } else {
              break; // Skip this line if searchWord doesn't match
            }
          } else {
            columns.push(currentColumn);
          }
          currentColumn = '';
        } else {
          currentColumn += char;
        }
      }
  
      // Add the last column after the loop ends
      columns.push(currentColumn);
  
      // Ignore the first entry (index 0) and save the rest as items in a single array
      if (!isFirstColumn && columns.length >= 6) {
        result.push(columns.slice(1));
      }
    }
  
    return result;
  }
  
  
  
  

  /*
  const dataArray = [
    ["one", "clue1", "clue2", "clue3", "clue4", "clue5"],
    ["two", "clue1", "clue2", "clue3", "clue4", "clue5"],
    ["three", "clue1", "clue2", "clue3", "clue4", "clue5"],
    ["four", "clue1", "clue2", "clue3", "clue4", "clue5"],
    ["five", "clue1", "clue2", "clue3", "clue4", "clue5"],
    ["six", "clue1", "clue2", "clue3", "clue4", "clue5"],
    ["seven", "clue1", "clue2", "clue3", "clue4", "clue5"],
    ["eight", "clue1", "clue2", "clue3", "clue4", "clue5"],
    ["nine", "clue1", "clue2", "clue3", "clue4", "clue5"],
    ["ten", "clue1", "clue2", "clue3", "clue4", "clue5"]
  ];
  */

  /**
   * Function to randomise the order of words to be placed into the puzzle.
   * @param {number} n The length of the array to be randomly ordered.
   * @return {Array} A randomly ordered array of numbers 1 through n.
  */
  function generateRandomArray(n) {
    // Generate running numbers array from 1 to n
    let arr = [];
    for (let i = 1; i <= n; i++) {
      arr.push(i);
    }

    // Scramble the array using Fisher-Yates shuffle algorithm
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }

    return arr;
  }
  /**
   * Function to create a queue of words and corresponding weights.
   * @param {*} arrays An array of arrays that stores words and clues in the standard format.
   * @param {*} weights An array of weights 1 through n where n is the length of the first argument.
   * @returns an object with enqueue and dequeue functions.
   */
  function createQueue(arrays, weights) {
    // Create an array of objects containing array and weight
    const queue = arrays.map((array, index) => ({ array, weight: weights[index] }));

    // Sort the queue based on weight in ascending order
    queue.sort((a, b) => a.weight - b.weight);

    // Create enqueue and dequeue functions
    const enqueue = (array, weight) => {
      queue.push({ array, weight });
      queue.sort((a, b) => a.weight - b.weight);
    };

    const dequeue = () => {
      return queue.shift()?.array;
    };

    const isEmpty = () => {
      return queue.length === 0;
    };

    const length = () => {
      return queue.length;
    };

    const arrayify = () => {
      return queue.map(item => item.array); // Extract the arrays from the queue
    };

    // Return an object with enqueue and dequeue functions
    return { enqueue, dequeue, isEmpty, length, arrayify };
  }

  const filePath = "/data/drugs.csv";
  const myKeyword = selectedKeyword;
  return fetch(filePath)
  .then(response => response.text())
  .then(csvData => {
    const dataArray = parseCSVData(csvData, myKeyword);
    const baseGrid = [];
    const results = permutate(dataArray, baseGrid);
    //console.log('Finished running');
    //console.log(...results);
    //displayMatrices(results);
    return results[0];
  })
  .catch(error => {
    console.error('Error fetching or parsing the CSV data:', error);
    return null;
  });


  /*
  function runHelper(queue, grid, rMax, cMax, rMin, cMin) {
    const result = helperPlace(queue, grid, rMax, cMax, rMin, cMin);
    
    if (result === false) {
      return;
    }
    
    if (typeof result === 'function') {
      
      result;
    }

    permutatedResults.push(result);
  }
  */

  //runHelper(wordQueue, baseGrid, 0, (firstItem[0].length - 1), 0, 0);

  function permutate(dataArray, clearGrid){
    //console.log("Array Order: ");
    //console.log(...dataArray);
    function helperPlace(list, grid, rMax, cMax, rMin, cMin) {
      //const myBranch = branches + 1;
      const myList = list.slice();
      var rowMax = rMax;
      var colMax = cMax;
      var rowMin = rMin;
      var colMin = cMin;
      //console.log(" ".repeat(60) + '-----     Running new instance of helper' + ' Branch: ' + myBranch + '     -----');
      //console.log(...grid);
      if (rMax - rMin + 1 > 20 || cMax - cMin + 1 > 20) {
        return;
      }
      if (foundSolution || timeOut) {
        return;
      }
      if (grid.length === 10) {
        //console.log('     ***Finished thread***     ');
        //return grid;
        //console.log("rowMax: " + rowMax);
        //console.log("rowMin: " + rowMin);
        //console.log("colMax: " + colMax);
        //console.log("colMin: " + colMin);
        foundSolution = true;
        permutatedResults.push(grid);
        return;
      }
      while (myList.length !== 0 && !foundSolution) {
        const currItem = myList.shift();
        //var currItemMatched = false;
        //console.log(" ".repeat(60) + '-----     ' + currItem + '     -----');
        //const dupeQueue = queue;
        //printQueue(dupeQueue);
        //console.log(...grid);
        const currWord = currItem[0];
        const currCharArr = currWord.split('');
        //test each letter in word to be placed
        var currLetterPos = -1;
        while (currCharArr.length > 0) {
          var currChar = currCharArr.shift();
          currLetterPos++;
        //for (let currLetterPos = 0; currLetterPos < currCharArr.length; currLetterPos++) {
          //let currChar = currCharArr[currLetterPos];
          //console.log(" ".repeat(130) + 'Current Branch: ' + myBranch);
          //console.log('Matching ' + currCharArr[currLetterPos] + ' in position ' + currLetterPos + ' of word ' + currWord);
          //console.log('Matching ' + currChar + ' in position ' + currLetterPos + ' of word ' + currWord);
          //find words that have matching letters
          const copyGrid = grid.slice();
          for (let gridPos = 0; gridPos < copyGrid.length; gridPos++) {
            const gridWord = copyGrid[gridPos];
          //var gridPos = -1;
          //var copyGrid = grid;
          //while (copyGrid.length > 0) {
            //const gridWord = copyGrid.shift();
            //gridPos++;
            const gridLetterMatchArr = gridWord[0][0].split('');
            //browse word on grid for matching letters
            var gridLetterPos = -1;
            //for (let gridLetterPos = 0; gridLetterPos < gridLetterMatchArr.length; gridLetterPos++) {
                //let gridLetter = gridLetterMatchArr[gridLetterPos];
            while (gridLetterMatchArr.length > 0) {
              //console.log(" ".repeat(130) + 'Current Branch: ' + myBranch);
              gridLetterPos++;
              var gridLetter = gridLetterMatchArr.shift();
              if (gridLetter === currChar) {
                //console.log(" ".repeat(130) + 'Current Branch: ' + myBranch);
                //let tempGrid = grid.slice();
                //displayMatrices([tempGrid]);
                //console.log('Potential match: ' + gridLetter + ' in position ' + gridLetterPos + ' of word ' + gridWord[0][0]);
                /*
                const returnVal = checkAllCollisions(queue, gridWord, item, i, l, grid, c, rowMax, colMax, rowMin, colMin);
                if (returnVal === false) {
                  //console.log('Invalid');
                  //pass
                } else if (returnVal === true) {
                  return false;
                } else if (typeof returnVal === 'function') {
                  //console.log('Valid');
                  return returnVal;
                }
                */
                const returnVal = checkAllCollisions(myList, currItem, gridLetterPos, currLetterPos, grid, gridPos, rowMax, colMax, rowMin, colMin);
                if (returnVal !== false) {
                  //currItemMatched = true;
                  //branches += 1;
                  //console.log(" ".repeat(130) + 'Branching: ' + (branches + 1));
                  let copyGrid = returnVal[1].slice();
                  //word with clues, row, col, direction
                  copyGrid.push([returnVal[6], returnVal[7], returnVal[8], returnVal[9]]);
                  helperPlace(returnVal[0], copyGrid, returnVal[2], returnVal[3], returnVal[4], returnVal[5]);
                }
              }
            }
          }
        }
        /*
        if (!currItemMatched) {
          //console.log(" ".repeat(60) + '-----     Recycling ' + currItem[0] + '     -----')
          recycleList.push(currItem);
        }
        */
      }
      //console.log('Finished Queue');
      /*
      if (!recycleQueue.isEmpty() && recycleQueue.length() < originalQueueLength) {
        //console.log(" ".repeat(90) + 'Recycling');
        //console.log(" ".repeat(90) + 'reQLen: ' + recycleQueue.length() + ' oriQLen: ' + originalQueueLength);
        const newBlankQueue = createQueue([],[]);
        helperPlace(recycleQueue, grid, rMax, cMax, rMin, cMin, newBlankQueue, recycleQueue.length());
      }
      */
      //TBC
      //Recursive steps are taking the same completed grid and adding to it.
      /*
      function restartProgram() {
        if (!foundSolution) {
          //console.log('Time limit reached. Restarting the program...');
          restart = true;
          return;
          //permutate(dataArray, clearGrid);
        }
      }
      */
      return;
    }
    //const timeLimit = 5000;
    const bankEntriesLength = dataArray.length;
    const randArr = generateRandomArray(bankEntriesLength);
    const baseList = createQueue(dataArray, randArr).arrayify();
    const baseGrid = clearGrid.slice();
    const permutatedResults = [];
    //const recycleList = [];
    let foundSolution = false;
    let timeOut = false;
    const firstItem = baseList.shift();
    baseGrid[0] = [firstItem, 0, 0, 0];
    helperPlace(baseList, baseGrid, 0, (firstItem[0].length - 1), 0, 0);
    //setTimeout(restartProgram, timeLimit);
    return permutatedResults;

  }

  function checkAllCollisions(myList, currWordFull, gridLetterPos, currLetterPos, grid, exempt, rowMax, colMax, rowMin, colMin) {
    const crsGridWord = grid[exempt];
    //console.log('Assessing validity...');
    const currWord = currWordFull[0];
    //const currWordRow = row;
    //const currWordCol = col;
    const currWordLength = currWord.length;
    //place current word down
    if (crsGridWord[3] === 0) {
      //console.log('Attempting to place ' + currWord + ' downward...');
      //check all other grid words
      const currWordCol = crsGridWord[2] + gridLetterPos;
      const currWordRow = crsGridWord[1] - currLetterPos;
      const currWordEndRow = currWordRow + currWordLength - 1;
      for (let i = 0; i < grid.length; i++) {
        //console.log('Testing collision with ' + grid[i][0][0] + '...');
        if (i === exempt) {
          //console.log(" ".repeat(60) + '##     Exempted');
          //do nothing
        } else {
          const gridWordRow = grid[i][1];
          const gridWordCol = grid[i][2];
          const gridWordLength = grid[i][0][0].length;
          const gridWordDirection = grid[i][3];
          //potential clash with across word
          if (gridWordDirection === 0) {
            const gridWordEndCol = gridWordCol + gridWordLength - 1;
            //above below rows
            if (gridWordRow === (currWordRow - 1) || gridWordRow === (currWordEndRow + 1)) {
              //console.log('Across Case 1: Touches top or bottom of placed word');
              //check if the test word collides
              if (gridWordEndCol >= currWordCol && gridWordCol <= currWordCol) {
                //collision
                //console.log(" ".repeat(50) + 'Failed:          Case 1');
                return false;
              }
              //within row range
            } else if (gridWordRow >= currWordRow && gridWordRow <= currWordEndRow) {
              //console.log('Across Case 2: Terminates on left side or originates on right side');
              //terminates left side
              if (gridWordEndCol === (currWordCol - 1)) {
                //collision
                //console.log(" ".repeat(50) + 'Failed:          Case 2 (terminates left)');
                return false;
              }
              //starts right side
              if (gridWordCol === (currWordCol + 1)) {
                //collision
                //console.log(" ".repeat(50) + 'Failed:          Case 2 (originates right)');
                return false;
              }
              if (gridWordCol <= currWordCol && gridWordEndCol >= currWordCol) {
                //console.log('Across Case 3: Potentially valid intersection');
                //test if the letter collision is valid
                const collisionWord = grid[i][0][0].split('');
                const placeWord = currWord.split('');
                /*
                console.log('Testing collision ' + placeWord[gridWordRow - currWordRow] + ' from ' + currWord + ' vs '
                  + collisionWord[currWordCol - gridWordCol] + ' from ' + grid[i][0][0] + '...');
                */
                if (placeWord[gridWordRow - currWordRow] !== collisionWord[currWordCol - gridWordCol]) {
                  //collision
                  //console.log(" ".repeat(60) + '##          Invalid Collision');
                  return false;
                }
                //console.log(" ".repeat(60) + '##          Valid Collision');
              }
            }
            //potential clash with another down word
            //ensure no down word enters one above or below the word same col, adjacent cols same rows
          } else {
            const gridWordEndRow = gridWordRow + gridWordLength - 1;
            //same column
            if (gridWordCol === currWordCol) {
              //console.log('Downward Case 1: Same column overlap: ' + gridWordCol);
              if (gridWordEndRow >= (currWordRow - 1) && (currWordEndRow + 1) >= gridWordRow) {
                //collision
                //console.log(" ".repeat(50) + 'Failed:          Case 1')
                return false;
              }
              //adjacent column
            } else if (gridWordCol === (currWordCol + 1) || gridWordCol === (currWordCol - 1)) {
              //console.log('Downward Case 2: Adjacent columns');
              if (gridWordEndRow >= currWordRow || currWordEndRow >= gridWordRow) {
                //console.log(" ".repeat(50) + 'Failed:          Case 2');
                //collision
                return false;
              }
            }
          }
        }
      }
      //TBC
      if (currWordRow < rowMin) {
        rowMin = currWordRow;
      } else if (currWordEndRow > rowMax) {
        rowMax = currWordEndRow;
      }
      if (currWordCol > colMax) {
        colMax = currWordCol;
      } else if (currWordCol < colMin) {
        colMin = currWordCol;
      }
      //console.log(" ".repeat(60) + '-----   Placed ' + currWord + ' at ' + 'R:' + currWordRow + ' C:' + currWordCol + ' Dir: Down -----');
      //grid.push([currWordFull, currWordRow, currWordCol, 1]);
      const returnVal = [myList, grid, rowMax, colMax, rowMin, colMin, currWordFull, currWordRow, currWordCol, 1];
      return returnVal;
      /*
      if (rowMax - rowMin > 20 || colMax - colMin > 20) {
        //console.log('Illegal Grid');
        return true;
      }
      return helperPlace(queue, grid, rowMax, colMax, rowMin, colMin);
      */
      //place current word Across
    } else if (crsGridWord[3] === 1) {
      //console.log('Attempting to place ' + currWord + ' across...');
      const currWordCol = crsGridWord[2] - currLetterPos;
      const currWordRow = crsGridWord[1] + gridLetterPos;
      const currWordEndCol = currWordCol + currWordLength - 1;
      for (let i = 0; i < grid.length; i++) {
        //console.log('Testing collision with ' + grid[i][0][0] + '...');
        if (i === exempt) {
          //console.log(" ".repeat(60) + '##     Exempted');
          //do nothing
        } else {
          const gridWordRow = grid[i][1];
          const gridWordCol = grid[i][2];
          const gridWordLength = grid[i][0][0].length;
          const gridWordDirection = grid[i][3];
          //potential clash with across word
          if (gridWordDirection === 0) {
            const gridWordEndCol = gridWordCol + gridWordLength - 1;
            //same row
            if (gridWordRow === currWordRow) {
              //console.log('Across Case 1: Same Row Overlap: ' + gridWordRow);
              if (gridWordEndCol >= (currWordCol - 1) && (currWordEndCol + 1) >= gridWordCol) {
                //collision
                //console.log(" ".repeat(50) + 'Failed:          Case 1');
                return false;
              }
              //adjacent row
            } else if (gridWordRow === (currWordRow + 1) || gridWordRow === (currWordRow - 1)) {
              //console.log('Across Case 2: Adjacent Row');
              if (gridWordEndCol >= currWordCol && currWordEndCol >= gridWordCol) {
                //collision
                //console.log(" ".repeat(50) + 'Failed:          Case 2');
                return false;
              }
            }
            //potential clash with down word
          } else {
            const gridWordEndRow = gridWordRow + gridWordLength - 1;
            //left right side
            if (gridWordCol === (currWordCol - 1) || gridWordCol === (currWordEndCol + 1)) {
              //console.log('Downward Case 1: Touches left or right of placed word');
              //check if the test word collides
              if (gridWordEndRow >= currWordRow && gridWordRow <= currWordRow) {
                //collision
                //console.log(" ".repeat(50) + 'Failed:          Case 1');
                return false;
              }
              //within col range
            } else if (gridWordCol >= currWordCol && gridWordCol <= currWordEndCol) {
              //console.log('Downward Case 2: Terminates on the top side or originates on the bottom side')
              //terminates top side
              if (gridWordEndRow === (currWordRow - 1)) {
                //collision
                //console.log(" ".repeat(50) + 'Failed:          Case 2 (terminates top side)');
                return false;
              }
              //starts bottom side
              if (gridWordEndRow === (currWordRow + 1)) {
                //collision
                //console.log(" ".repeat(50) + 'Failed:          Case 2 (originates bottom side)');
                return false;
              }
              if (gridWordRow <= currWordRow && gridWordEndRow >= currWordRow) {
                //console.log('Downward Case 3: Potentially valid intersection');
                //test if the letter collision is valid
                const collisionWord = grid[i][0][0].split('');
                const placeWord = currWord.split('');
                /*
                console.log('Testing collision ' + placeWord[gridWordCol - currWordCol] + ' from ' + currWord + ' vs '
                  + collisionWord[currWordRow - gridWordRow] + ' from ' + grid[i][0][0] + '...');
                */
                if (placeWord[gridWordCol - currWordCol] !== collisionWord[currWordRow - gridWordRow]) {
                  //collision
                  //console.log(" ".repeat(60) + '##          Invalid Collision');
                  return false;
                }
                //console.log(" ".repeat(60) + '##          Valid Collision');
              }
            }
          }
        }
      }
      //TBC
      if (currWordCol < colMin) {
        colMin = currWordCol;
      } else if (currWordEndCol > colMax) {
        colMax = currWordEndCol;
      }
      if (currWordRow > rowMax) {
        rowMax = currWordRow;
      } else if (currWordRow < rowMin) {
        rowMin = currWordRow;
      }
      //console.log(" ".repeat(60) + '-----   Placed ' + currWord + ' at ' + 'R:' + currWordRow + ' C:' + currWordCol + ' Dir: Across -----');
      //grid.push([currWordFull, currWordRow, currWordCol, 0]);
      const returnVal = [myList, grid, rowMax, colMax, rowMin, colMin, currWordFull, currWordRow, currWordCol, 0];
      return returnVal;
      /*
      if (rowMax - rowMin > 20 || colMax - colMin > 20) {
        //console.log('Illegal Grid');
        return true;
      }
      return helperPlace(queue, grid, rowMax, colMax, rowMin, colMin);
      */
    }
  }

  //Each word is placed in all valid locations and individually recurses
  //Once the word limit is reached, add to an array
  //Score all array entries
  //If no valid crossword is successfully built, reseed
  /*
  function displayMatrices(list) {
    for (let listIndex = 0; listIndex < list.length; listIndex++) {
      let grid = list[listIndex];
      let minRow;
      let minCol;
      let maxRow;
      let maxCol;
      let display = [];
      for (let gridIndex = 0; gridIndex < grid.length; gridIndex++) {
        let cRow = grid[gridIndex][1];
        let cCol = grid[gridIndex][2];
        if (minRow === undefined || cRow < minRow) {
          minRow = cRow;
        }
        if (minCol === undefined || cCol < minCol) {
          minCol = cCol;
        }
      }
      for (let gridIndex = 0; gridIndex < grid.length; gridIndex++) {
        let cDir = grid[gridIndex][3];
        let cWord = grid[gridIndex][0][0];
        let cR = grid[gridIndex][1];
        let cC = grid[gridIndex][2];
        if (cDir === 0) {
          for (let wordIndex = 0; wordIndex < cWord.length; wordIndex++) {
            let cLetter = cWord.charAt(wordIndex);
            let mCol = cC - minCol + wordIndex;
            addToArray(display, cR - minRow, mCol, cLetter);
            if (maxCol === undefined || maxCol < mCol) {
              maxCol = mCol;
            }
            if (maxRow === undefined || maxRow < cR) {
              maxRow = cR;
            }
          }
        } else {
          for (let wordIndex = 0; wordIndex < cWord.length; wordIndex++) {
            let cLetter = cWord.charAt(wordIndex);
            let mRow = cR - minRow + wordIndex;
            addToArray(display, mRow, cC - minCol, cLetter);
            if (maxRow === undefined || maxRow < mRow) {
              maxRow = mRow;
            }
            if (maxCol === undefined || maxCol < cC) {
              maxRow = cC;
            }
          }
        }
      }
      //console.log("Grid permutation: " + (listIndex + 1) + " Grid Items: " + grid.length);
      //console.log(maxRow);
      //console.log(minRow);
      //console.log("Grid Cols: " + (maxCol - minCol + 1));
      displayMatrix(display, minCol, maxCol);
      //console.log('\n');
    }
    return;
  }

  function addToArray(arr, row, col, char) {
    if (arr[row] === undefined) {
      arr[row] = [];
    }

    arr[row][col] = char;
  }

  function displayMatrix(arr, minCol, maxCol) {
    //console.log("Grid Rows: " + arr.length);
    //console.log(maxCol);
    //console.log(minCol);
    // Find the maximum length among the subarrays
    const maxLength = (maxCol - minCol) + 1;



    // Pad the subarrays with undefined values to make them equal length
    const paddedArr = arr.map(subarray => {
      const paddingCount = maxLength - subarray.length;
      return subarray.concat(Array(paddingCount).fill('#'));
    });

    // Print the matrix
    for (let row of paddedArr) {
      const processedRow = Array.from(row, (item => (item === undefined ? '#' : item)));
      //console.log(processedRow.join(' '));
    }
  }
  */
}
