// MoreModal.tsx
import React from 'react';
import styled from 'styled-components';

const HyperLink = styled.a`
  color: #007bff; /* Change link color as needed */
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  padding: 0.5em;
  font-size: 18px;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 700;
`;
const Paragraph = styled.span`
  font-size: 18px;
  max-width: 100%;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 35px;
  border-radius: 4px;
  gap: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-align: center;
`;

const CommandButton = styled.button`
  margin-right: 0em;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const StartTimerIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Start Timer button */}
    {/* Example icon: */}
    <path d="M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM12,2A10,10,0,1,0,22,12,10.011,10.011,0,0,0,12,2Z"/><path d="M9,17.619a1,1,0,0,1-1-1V7.381a1,1,0,0,1,1.5-.866l8,4.619a1,1,0,0,1,0,1.732l-8,4.619A1,1,0,0,1,9,17.619Zm1-8.506v5.774L15,12Z"/>
  </svg>
);

const StopTimerIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Stop Timer button */}
    {/* Example icon: */}
    <path d="M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM12,2A10,10,0,1,0,22,12,10.011,10.011,0,0,0,12,2Z"/><path d="M10,16.993a1,1,0,0,1-1-1V8.019a1,1,0,1,1,2,0v7.974A1,1,0,0,1,10,16.993Z"/><path d="M14,16.993a1,1,0,0,1-1-1V8.019a1,1,0,1,1,2,0v7.974A1,1,0,0,1,14,16.993Z"/>
  </svg>
);

const HintIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Hint button */}
    {/* Example icon: */}
    <path d="M12,19C5.526,19,.44,12.9.227,12.634a1,1,0,0,1,0-1.268C.44,11.105,5.526,5,12,5s11.56,6.105,11.773,6.366a1,1,0,0,1,0,1.268C23.56,12.9,18.474,19,12,19ZM2.349,12C3.764,13.472,7.611,17,12,17s8.239-3.525,9.651-5C20.236,10.528,16.389,7,12,7S3.761,10.525,2.349,12Z"/><path d="M12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,12,10Z"/>
  </svg>
);

const ResetIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Reset button */}
    {/* Example icon: */}
    <path d="M13.469,22.531A10.525,10.525,0,0,1,8.68,21.38,1,1,0,0,1,9.594,19.6a8.4,8.4,0,0,0,3.875.93A8.531,8.531,0,1,0,4.938,12a1,1,0,0,1-2,0A10.531,10.531,0,1,1,13.469,22.531Z"/><path d="M3.994,14.458a1,1,0,0,1-.707-.292l-3-3A1,1,0,0,1,1.7,9.751l2.293,2.293L6.287,9.751A1,1,0,0,1,7.7,11.166l-3,3A1,1,0,0,1,3.994,14.458Z"/>
  </svg>
);

const DropdownIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Reset button */}
    {/* Example icon: */}
    <path d="M21,24H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H21a3,3,0,0,1,3,3V21A3,3,0,0,1,21,24ZM3,2A1,1,0,0,0,2,3V21a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1Z"/><path d="M12,15a1,1,0,0,1-.707-.293l-4-4A1,1,0,1,1,8.707,9.293l4,4A1,1,0,0,1,12,15Z"/><path d="M12,15a1,1,0,0,1-.707-1.707l4-4a1,1,0,0,1,1.414,1.414l-4,4A1,1,0,0,1,12,15Z"/>
  </svg>
);

const MoreIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Reset button */}
    {/* Example icon: */}
    <path d="M4,15a3,3,0,1,1,3-3A3,3,0,0,1,4,15Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,4,11Z"/><path d="M12,15a3,3,0,1,1,3-3A3,3,0,0,1,12,15Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,12,11Z"/><path d="M20,15a3,3,0,1,1,3-3A3,3,0,0,1,20,15Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,20,11Z"/>
  </svg>
);

const HelpIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Help button */}
    {/* Example icon: */}
  <path d="m637.5 862.5c0 20.711-16.789 37.5-37.5 37.5s-37.5-16.789-37.5-37.5 16.789-37.5 37.5-37.5 37.5 16.789 37.5 37.5" fill-rule="evenodd"/>
  <path d="m600 75c-289.5 0-525 235.5-525 525s235.5 525 525 525 525-235.5 525-525-235.5-525-525-525zm0 75c248.97 0 450 201.03 450 450s-201.03 450-450 450-450-201.03-450-450 201.03-450 450-450z"/>
  <path d="m600 300c-103.09 0-187.5 84.41-187.5 187.5-0.14062 10.039 3.7461 19.715 10.793 26.859 7.0508 7.1484 16.668 11.176 26.707 11.176s19.656-4.0273 26.707-11.176c7.0469-7.1445 10.934-16.82 10.793-26.859 0-62.508 49.992-112.5 112.5-112.5s112.5 49.992 112.5 112.5-49.992 112.5-112.5 112.5c-9.9453 0-19.484 3.9531-26.516 10.984s-10.984 16.57-10.984 26.516v75c-0.14062 10.039 3.7461 19.715 10.793 26.859 7.0508 7.1484 16.668 11.176 26.707 11.176s19.656-4.0273 26.707-11.176c7.0469-7.1445 10.934-16.82 10.793-26.859v-45.117c84.676-17.992 150-90.066 150-179.88 0-103.09-84.41-187.5-187.5-187.5z"/>
  </svg>
);

const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill= "black"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Add your SVG icon for the Start Timer button */}
    {/* Example icon: */}
    <path d="M11.978,23.941A11.964,11.964,0,1,1,23.941,11.978,11.976,11.976,0,0,1,11.978,23.941Zm0-21.927a9.964,9.964,0,1,0,9.963,9.964A9.975,9.975,0,0,0,11.978,2.014Z"/><path d="M16,17a1,1,0,0,1-.707-.293l-8-8A1,1,0,1,1,8.707,7.293l8,8A1,1,0,0,1,16,17Z"/><path d="M8,17a1,1,0,0,1-.707-1.707l8-8a1,1,0,1,1,1.414,1.414l-8,8A1,1,0,0,1,8,17Z"/>
  </svg>
);

interface MoreModalProps {
  onClose: () => void;
  children?: React.ReactNode; // Add this line
}

const MoreModal: React.FC<MoreModalProps> = ({ onClose, children}) => {
  return (
    <ModalOverlay>
      <ModalContent>
        {children}
        <Header>Toolbar Buttons:</Header>
        <TextRow><StartTimerIcon/><StopTimerIcon/>Start/Stop the timer.</TextRow>
        <TextRow><HintIcon/>View puzzle solution.</TextRow>
        <TextRow><ResetIcon/>Clear the grid.</TextRow>
        <TextRow><DropdownIcon/>Choose new puzzle.</TextRow>
        <Paragraph>The puzzle automatically checks for correct answers and strikes them out.</Paragraph>
        <CommandButton onClick={onClose} title="Close">
            {<CloseIcon/>}
        </CommandButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default MoreModal;